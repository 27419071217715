import React from 'react'
import SEO from "../../components/seo"
import DashboardLayout from '../../components/DashboardLayout'
import Refer from '../../components/DashboardContent/Refer'

const refer = () => {
    return (
        <>
        <SEO title="Dashboard" />
        <DashboardLayout>
            <Refer />
        </DashboardLayout>
            
        </>
    )
}

export default refer
